import React from 'react'

export default function StreamingLinks(props) {

    const { streamData, className, titleContext, sectionTitle } = props;

    let streamClassName = 'flex flex-col items-start';

    if (className) streamClassName = [streamClassName, className].join(" ");


    let titleAttr = 'Listen on';

    if (titleContext) titleAttr = titleContext;
    

    const linkAtts = {
        target: `_blank`,
        rel: `noopener noreferrer`,
        className: `text-xxs uppercase tracking-wide leading-1 inline-block hover:underline underline-offset-2`,
        style: {textDecorationThickness: `1.5px`},
    }


    if (!streamData) return null;

    return (


       <div>
           {sectionTitle &&

            <h4 className={`text-xs font-bold uppercase mb-1.5`}>{sectionTitle}</h4>

           }
            <div className={streamClassName}>
            {streamData.spotify &&
                <span>
                <a href={streamData.spotify} title={`${titleAttr} Spotify`} {...linkAtts}>Spotify</a>
                </span>
            }
            {streamData.appleMusic &&
                <span>
                <a href={streamData.appleMusic} title={`${titleAttr} Apple Music`} {...linkAtts}>Apple Music</a>
                </span>
                }
            {streamData.youtube &&
            <span>
            <a href={streamData.youtube} title={`${titleAttr} YouTube`} {...linkAtts}>YouTube</a>
            </span>
            }

            {streamData.soundcloud &&
            <span>
            <a href={streamData.soundcloud} title={`${titleAttr} SoundCloud`} {...linkAtts}>SoundCloud</a>
            </span>
            }

            {streamData.bandcamp &&
            <span>
            <a href={streamData.bandcamp} title={`${titleAttr} Bandcamp`} {...linkAtts}>Bandcamp</a>
            </span>
            }

            {streamData.amazon &&
            <span>
            <a href={streamData.amazon} title={`${titleAttr} Amazon`} {...linkAtts}>Amazon Music</a>
            </span>
            }

            {streamData.pandora &&
            <span>
            <a href={streamData.pandora} title={`${titleAttr} Pandora`} {...linkAtts}>Pandora</a>
            </span>
            }

            {streamData.tidal &&
            <span>
            <a href={streamData.tidal} title={`${titleAttr} Tidal`} {...linkAtts}>Tidal</a>
            </span>
           }

          {streamData.deezer &&
            <span>
            <a href={streamData.deezer} title={`${titleAttr} Deezer`} {...linkAtts}>Deezer</a>
            </span>
           }

            {streamData.bandcampBuy &&
            <span>
            <a href={streamData.bandcampBuy} title={`${titleAttr} Bandcamp`} {...linkAtts}>Bandcamp</a>
            </span>
           }

            {streamData.amazonBuy &&
            <span>
            <a href={streamData.amazonBuy} title={`${titleAttr} Amazon`} {...linkAtts}>Amazon</a>
            </span>
           }

          {streamData.itunes &&
            <span>
            <a href={streamData.itunes} title={`${titleAttr} iTunes`} {...linkAtts}>iTunes</a>
            </span>
           }
        </div>
       </div>
    )
}