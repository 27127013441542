import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { imageCrop } from "../styles/liner.module.scss"

const ReleaseMedia = props => {

    const { mediaData, className } = props;

    const media1 = mediaData?.additionalMedia1
    const media2 = mediaData?.additionalMedia2
    const media3 = mediaData?.additionalMedia3
    const media4 = mediaData?.additionalMedia4
    const media5 = mediaData?.additionalMedia5

    const nArrays = [
        media1,
        media2,
        media3,
        media4,
        media5
    ]

    const flatArray = [].concat(...nArrays);

    function removeNull(array) {
        return array.filter(x => x !== null)
        }
    const filteredArray = removeNull(flatArray);
    
    if (filteredArray.length  === 0) return null;

    let releaseMediaClassName = 'w-full';
    if (className) releaseMediaClassName = [releaseMediaClassName, className].join(" ");

    return (
        <div className={releaseMediaClassName}>
                <div className={`-mb-2`}>
                <div className={`flex flex-row items-start px-[0.2rem] pb-[0.2rem]`}>
                        {filteredArray.map((img, index) => {
                            return (
                                <div key={index} className={`flex-true p-1`}>
                                    <figure className={`${imageCrop} shadow-lg`}>
                                        <GatsbyImage 
                                        image={getImage(img.localFile)}
                                        alt={img.altText || `Milo Walker Johnson`}
                                        className={`border-2 border-prose`}
                                        imgStyle={{width: '103%'}}
                                        />
                                    </figure>

                                </div>
                            )
                        })}
                </div>
                </div>

        </div>
    )
}

export default ReleaseMedia