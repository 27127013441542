import * as React from 'react'
import Layout from "../components/Layout"
import Seo from "../components/seo"
import { graphql, navigate } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Container from '../components/Container'
import { PageTitle, SectionTitle } from "../components/Titles"
import { linerNotes } from "../styles/liner.module.scss"
import { useMediaQuery } from 'react-responsive'
import ReleaseMedia from '../components/ReleaseMedia'
import StreamingLinks from '../components/StreamingLinks'
import Button, { BackButton } from '../components/Button'
import { AnchorLink } from "gatsby-plugin-anchor-links";
// import { useColor } from 'color-thief-react'


const ReleaseTemplate = ( {data} ) => {

    const artistName = "Milo Walker Johnson";

    const imageData = getImage(data.wpMusicRelease.featuredImage?.node?.localFile)

    const aboveMd = useMediaQuery({ query: '(min-width: 768px)' });

    const hgroupStyle = {
        writingMode: aboveMd ? 'unset' : 'unset'
    }

    // const colorVibe = data.wpMusicRelease.featuredImage.node.localFile.colors.lightVibrant;

    // function hexToRgbA(hex){
    //     var c;
    //     if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
    //         c= hex.substring(1).split('');
    //         if(c.length== 3){
    //             c= [c[0], c[0], c[1], c[1], c[2], c[2]];
    //         }
    //         c= '0x'+c.join('');
    //         return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.5)';
    //     }
    //     throw new Error('Bad Hex');
    // }

    //   const vibeRgb = hexToRgbA(colorVibe);

    // React.useEffect(() => {
    //     // document.documentElement.style.setProperty('--background-color', colorVibe);
    //     document.documentElement.style.setProperty('--text-color', data.wpMusicRelease.featuredImage.node.localFile.colors.darkVibrant);
    //     document.documentElement.style.setProperty('--color-accent', data.wpMusicRelease.featuredImage.node.localFile.colors.darkMuted);
    // })


    const domCol = data.wpMusicRelease.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData?.backgroundColor;

    // React.useEffect(() => {
    //     document.documentElement.style.setProperty('--color-accent', domCol);
    // });

    if (!imageData) return null

   

    return (
        <Layout>
            <Seo title={data.wpMusicRelease.title} />
            <Container size={`lg`}>
                <div className={`py-2 -mb-5 md:-mx-[0.5rem] lg:absolute lg:z-10 lg:left-auto lg:top-0 lg:right-14`}>
                    <BackButton variation={`outline`} onClick={() => navigate(-1)}>
                        Back
                    </BackButton>
                </div>
            </Container>
            <Container size={`lg`} className={`py-12 sm:py-16 md:py-20`}>

              <div className={`flex flex-row items-center md:-m-5 lg:-m-10 md:flex-nowrap flex-wrap`}>
                  <div className={`md:flex-true md:p-5 lg:p-10 max-w-full`}>
                  <GatsbyImage 
                    image={imageData}
                    layout={`fullWidth`}
                    className={`w-full item-shadow pointer-events-none`}
                    alt={data.wpMusicRelease.featuredImage.node.altText || data.wpMusicRelease.title}
                    />
                  </div>
                  <div className={`md:flex-true max-w-full pt-5 md:p-5 md:pb-5 lg:p-10 lg:pb-10 pb-10`}>
                  
                  <hgroup className={`mb-0`} style={hgroupStyle}>
                  <p className={`mb-3 text-sm`}>By <strong>{artistName}</strong></p>
                  <PageTitle size={`text-4xl font-bold italic uppercase md:text-5xl lg:text-6xl xl:text-8xl md:inline-block -ml-1`}>{data.wpMusicRelease.title}</PageTitle>
                  <p className={`hidden`}>{domCol}</p>
                  </hgroup>
                  <div className={`pt-5 space-y-5 lg:space-y-7`}>
                  
                  {
                      data.wpMusicRelease.releaseFormats.nodes.length > 0 &&

                      <div className={`text-xs tracking-wider uppercase`}>
                          <h4 className={`text-xs font-bold uppercase block mb-2`}>Format</h4>

                        {
                      data.wpMusicRelease.releaseFormats.nodes.map((form, index) => {

                        return <span className={`text-xxs`} key={form.id}>{ index ? ' / ' : ''} {form.name} </span>
                      })
                  }

                      </div>
                  }

                  {(data.wpMusicRelease.releaseLinks.spotify || data.wpMusicRelease.releaseLinks.appleMusic) &&
                    <StreamingLinks 
                    sectionTitle={`Stream`}
                    className={`grid grid-cols-2 gap-x-5 gap-y-2 md:gap-0 lg:max-w-[65%]`}
                    streamData={data.wpMusicRelease.releaseLinks } titleContext={`Listen to ${data.wpMusicRelease.title} on`} />
                  }

                {(data.wpMusicRelease.purchaseLinks.bandcampBuy || data.wpMusicRelease.purchaseLinks.amazonBuy || data.wpMusicRelease.purchaseLinks.itunes) &&
                    <StreamingLinks 
                    sectionTitle={`Buy`}
                    className={`grid grid-cols-2 gap-x-5 gap-y-2 md:gap-0 lg:max-w-[65%]`}
                    streamData={data.wpMusicRelease.purchaseLinks } titleContext={`Purchase ${data.wpMusicRelease.title} on`} />
                  }

                  {data.wpMusicRelease.releaseSettings.releasePurchaseLink !== null && 
                  
                  <Button as={"a"} href={`https://shop.milowalkerjohnson.com${data.wpMusicRelease.releaseSettings.releasePurchaseLink.url}`} type={`external`}>{data.wpMusicRelease.releaseSettings.releasePurchaseLink.title}</Button>
                  }

                  {
                    data.wpMusicRelease.albumSettings.releaseLinerNotes && 

                    <Button as={AnchorLink} to={`#liner-notes`} variation={`outline`} stripHash={true}>Liner Notes</Button>
                  }
                  </div>
                  </div>
              </div> 

        {

            

            data.wpMusicRelease.albumSettings.releaseLinerNotes &&
            

            <div className={`pt-20 md:pt-24`} id={`liner-notes`}>
                <SectionTitle as={`h3`} className={`text-accent`}>Liner Notes</SectionTitle>
                <div>
                <div className={`columns-2 lg:columns-3 gap-5 lg:gap-10 ${linerNotes}`}>
                <div
                    className={`text-justify`} 
                    dangerouslySetInnerHTML={{__html: data.wpMusicRelease.albumSettings.releaseLinerNotes}}
                    />
            </div> 
                </div>
            </div>
        }
            
            </Container>
            <ReleaseMedia mediaData={data.wpMusicRelease.albumSettings.additionalMedia} className={`mb-20`} />
        </Layout>
    )

}

export default ReleaseTemplate 

export const query = graphql`
  query($id: String) {
    wpMusicRelease(id: { eq: $id }) {
      title
      id 
      slug
      date
      releaseFormats {
        nodes {
          name
          slug
          id
        }
      }
      releaseLinks {
        appleMusic
        spotify
        youtube
        tidal
        soundcloud
        pandora
        amazon
        deezer
      }
      purchaseLinks {
        bandcampBuy
        amazonBuy
        itunes
      }
      releaseSettings {
        releasePurchaseLink {
          target
          title
          url
        }
      }
      albumSettings {
        releaseLinerNotes
        additionalMedia {
            additionalMedia1 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            additionalMedia2 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              additionalMedia3 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              additionalMedia4 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              additionalMedia5 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
          }
          
      }
      featuredImage {
        node {
          altText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(quality: 80)
            }
            
          }
          
        }
      }
    }
  }
`